import Vue from "vue";
import FetchService from "./fetch-service.js";

const vue = new Vue();

class VoucherService extends FetchService {

    async validarCupom(codigo) {
        const route = `${vue.$globals.endpoint}clientevoucher/validaCodigo?codigo=${codigo}`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            null, 
            false, 
            route
        );
    }

    listarFiltros() {
        return {
            todos: null,
            descricao: null,
            desconto: null,
            servico: null,
            ponto: null,
            dataInicio: null,
            dataFim: null,
            status: null,
            ordenarPor: 0,
            ordem: 0,
            pagina: 1,
            itensPagina: 10,
        };
    }

    async listarCampos() {
        return [
            {
                descricao: "Descrição",
                valor: "descricao",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: true,
            },
            {
                descricao: "Desconto (%)",
                valor: "descontoFormatado",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: false,
            },
            {
                descricao: "Serviço",
                valor: "servico",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: false,
            },
            {
                descricao: "Pontos",
                valor: "ponto",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: false,
            },
            {
                descricao: "Início",
                valor: "dataInicioFormatada",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: false,
            },
            {
                descricao: "Fim",
                valor: "dataFimFormatada",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: false,
            },
            {
                descricao: "Status",
                valor: "statusFormatado",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: true,
            },
        ]
    }

    async listarVouchers(filtro) {
        const route = `${vue.$globals.endpoint}voucher/listarVouchers`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            filtro, 
            false, 
            route
        );
    }

    async exportarVouchersExcel(filtro) {
        const route = `${vue.$globals.endpoint}voucher/exportarVouchersExcel`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            filtro, 
            true, 
            route
        );
    }

    async cadastrar(obj) {
        const route = `${vue.$globals.endpoint}voucher`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            obj, 
            false, 
            route
        );
    }

    async atualizar(obj) {
        const route = `${vue.$globals.endpoint}voucher`;
        return await this.fetchResponse(
            "PUT", 
            vue.$globals.headerPadrao, 
            obj, 
            false, 
            route
        );
    }

    async listarTodasCategorias(listaCompleta) {
        const route = `${vue.$globals.endpoint}voucherCategoria?listaCompleta=${listaCompleta}`;
        return await this.fetchResponse(
            "GET", 
            vue.$globals.headerPadrao, 
            null, 
            false, 
            route
        );
    }

    async listarClienteVouchers(clienteId) {
        const route = `${vue.$globals.endpoint}clientevoucher/getByClienteId?id=${clienteId}`;
        return await this.fetchResponse(
            "GET", 
            vue.$globals.headerPadrao, 
            null, 
            false, 
            route
        );
    }

    async getCupons(clienteId) {
        const route = `${vue.$globals.endpoint}clientevoucher?clienteId=${clienteId}`;
        return await this.fetchResponse(
            "GET", 
            vue.$globals.headerPadrao, 
            null, 
            false, 
            route
        );
    }

    async getById(id,caminho, validar = true) {
        const route = `${vue.$globals.endpoint}clientevoucher/getById?id=${id}&caminho=${caminho}`;
        return await this.fetchResponse(
            "GET", 
            vue.$globals.headerPadrao, 
            null, 
            false, 
            route,
            validar
        );
    }

    async getByVoucherId(id,caminho, validar = true) {
        const route = `${vue.$globals.endpoint}clientevoucher/getByVoucherId?id=${id}&caminho=${caminho}`;
        return await this.fetchResponse(
            "GET", 
            vue.$globals.headerPadrao, 
            null, 
            false, 
            route,
            validar
        );
    }

    async validaQRCodeVoucher(token) {
        const route = `${vue.$globals.endpoint}clientevoucher/validaQRCodeVoucher?token=${token}`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            null, 
            false, 
            route
        );
    }

    async listarVouchersPontos() {
        const route = `${vue.$globals.endpoint}voucher/listarVouchersPontos`;
        return await this.fetchResponse(
            "GET", 
            vue.$globals.headerPadrao, 
            null, 
            false, 
            route
        );
    }

    async gerarVoucherPontos(voucherid, clienteId) {
        const route = `${vue.$globals.endpoint}clientevoucher/gerarVoucherPontos?voucherid=${voucherid}&clienteId=${clienteId}`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            null, 
            false, 
            route
        );
    }

    async gerarVoucherPrimeiroAcesso(clienteId, caminho) {
        const route = `${vue.$globals.endpoint}clientevoucher/gerarVoucherPrimeiroAcesso?clienteId=${clienteId}&caminho=${caminho}`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            null, 
            false, 
            route
        );
    }
}

export default VoucherService;