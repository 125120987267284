import Vue from "vue";
import FetchService from "./fetch-service.js";

const vue = new Vue();

class ServicoService extends FetchService {

    listarFiltros() {
        return {
            todos: null,
            descricao: null,
            ponto: null,
            statusFormatado: null,
            ordenarPor: 0,
            ordem: 0,
            pagina: 1,
            itensPagina: 10,
        };
    }

    async listarCampos() {
        return [
            {
                descricao: "Serviço",
                valor: "descricao",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: false,
            },
            {
                descricao: "Pontos",
                valor: "ponto",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: true,
            },
            {
                descricao: "Status",
                valor: "statusFormatado",
                selecionado: null,
                editavel: false,
                filtravel: false,
                tipo: "texto",
                ordenado: null,
                ocultarResponsivo: true,
            },
        ]
    }

    async listarTodosServicos(listaCompleta) {
        const route = `${vue.$globals.endpoint}servico?listaCompleta=${listaCompleta}`;
        return await this.fetchResponse(
            "GET", 
            vue.$globals.headerPadrao, 
            null, 
            false, 
            route
        );
    }

    async listarServicos(filtro) {
        const route = `${vue.$globals.endpoint}servico/listarServicos`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            filtro, 
            false, 
            route
        );
    }

    async exportarServicosExcel(filtro) {
        const route = `${vue.$globals.endpoint}servico/exportarServicosExcel`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            filtro, 
            true, 
            route
        );
    }

    async cadastrar(obj) {
        const route = `${vue.$globals.endpoint}servico`;
        return await this.fetchResponse(
            "POST", 
            vue.$globals.headerPadrao, 
            obj, 
            false, 
            route
        );
    }

    async atualizar(obj) {
        const route = `${vue.$globals.endpoint}servico`;
        return await this.fetchResponse(
            "PUT", 
            vue.$globals.headerPadrao, 
            obj, 
            false, 
            route
        );
    }
}

export default ServicoService;